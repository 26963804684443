namespace _System 
{
    export function _SetSystem()
    {
        // // console.log("intro SetSystem _System");
        // // if(SystemJS != undefined && SystemJS != null)
        // //     console.log(SystemJS);
        // // else
        // // console.log("*********** SetSystem undefined");
        
        SystemJS.config({
            baseURL: '/',
            map: {
                d3: "libs/d3.min.js"
            },
            meta: {
                "d3": {
                    format: 'global',
                    exports: "d3"
                }
            },
            defaultJSExtensions:true
        });
    }    

    export function _ImportModule(nombre: string)
    {
        SystemJS.import(nombre);
    }
    _SetSystem();
}